<template>
  <div>
    <div class="accordion mb-2" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            @click="active = !active"
            style="background:#e5e9f0; color:black !important; border:none !important;"
          >
            <div class="row">
              <div class="col text-left mt-1">
                <i class="mdi mdi-format-list-bulleted"></i>
                {{ roomType }}
                
              </div>
              <div class="col text-right">
                <!-- Total: {{ service[roomType][0].subTotal }}. -->
                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i>
                <i v-else class="mdi mdi-chevron-down font-size-18"></i>
              </div>
            </div>
          </b-button>
        </b-card-header>
        <b-collapse
          :id="`accordion-${pos}`"
          :visible="active"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body class="shadow-lg">
            <fieldset >
              <div class="mt-3">
                <div class="row">
                  <div class="col-lg-3">
                    <label for="">Consommation Mensuelle (Système)</label>
                    <input
                      type="text"
                      class="form-control bg-light"
                      :value="countTotalBookingNights(service[roomType][0].room_type)"
                      readonly
                    />
                  </div>
                  <div class="col-lg-3">
                    <label for="">Consommation Mensuelle (Réelle)</label>
                    <input
                      type="text"
                      class="form-control bg-light"
                      v-model="service[roomType][0].quantity"
                      readonly
                    />
                  </div>

                  <div class="col-lg-3">
                    <label for="">Consommation Mensuelle (Prestataire)</label>
                    <input
                      type="number"
                      :disabled="ODS.read_at==null"
                      v-model="service[roomType][0].quantity_prest"
                      class="form-control"
                      min="0"
                      @input="_emitData"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>

export default {
    props: {
      service: null,
      roomType: null,
      ODS: null,
      send: null,
      pos: null,
      estimated: null,
      // disable: false,
    },
    data() {
      return {
        active:false,
        roomsFinalData: {},
      }
    },
    watch: {
      service(){
        if(this.service){
          this.roomsFinalData = this.service[this.roomType];
        }
      }
    },

    methods: {
      countTotalBookingNights(roomTypeData){
          var total = 0;
          if(roomTypeData.bookings != undefined){
            roomTypeData.bookings.forEach(element => {
                total += parseInt(element.nights);
            });
          }else{
            total = roomTypeData.quantity;
            roomTypeData.realQty = total;
          }
          return total;
      },

      getTotalAmnt(roomTypeData){
        var total = 0;
        var qty = roomTypeData.realQty;
        var unitPrice = 0;
        if(roomTypeData.unit_price != undefined){
          unitPrice = roomTypeData.unit_price
        }else{
          unitPrice = 0;
        }
        console.log("unitPrice => "+unitPrice);
        total = parseFloat(unitPrice) * parseFloat(qty);
        console.log("qty => "+qty);
        if(isNaN(total)){
          total = 0;
        }
        roomTypeData.subTotal = total;
        this._emitData();
        return  new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(total);
      },

      _emitData(){
        this.$emit('getvalues',this.service[this.roomType]);
        this.$emit('getvaluesEmitted',true);
      }
    },

};
</script>
